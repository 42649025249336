import { graphql } from '@/gql';

const query = graphql(`
  query accountActivationCheck {
    viewer2 {
      ... on ViewerQuerySuccessPayload {
        result {
          id
          unactivatedEmail
          isActivated2
        }
      }
    }
  }
`);

export const useAsyncAccountActivationCheck = async () => {
  const {
    data: rawResult,
    error,
    refresh,
  } = await useAuthedQuery(
    query,
    {},
    {
      apollo: { fetchPolicy: 'no-cache' },
    }
  );

  const { isAuthenticated } = useAuthenticationState();
  watch(isAuthenticated, async () => await refresh());

  const result = computed(() => {
    if (rawResult.value?.viewer2.__typename === 'ViewerQueryErrorPayload') {
      return {
        isAuthenticated: false,
        isActivated: false,
      } as const;
    }
    if (rawResult.value?.viewer2.__typename === 'ViewerQuerySuccessPayload') {
      return {
        isAuthenticated: true,
        isActivated: rawResult.value.viewer2.result.isActivated2,
        email: rawResult.value.viewer2.result.unactivatedEmail as string, // activateされてないときは絶対activateしてないメールアドレスある
      };
    }
  });

  return {
    result,
    error,
  };
};
